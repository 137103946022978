@font-face {
    font-family: 'Fabrik';
    src: url(./fonts/Fabrik-Regular.otf);
    font-weight: normal;
}

@font-face {
    font-family: 'Fabrik';
    src: url(./fonts/Fabrik-Bold.otf);
    font-weight: 600;
}

@font-face {
    font-family: 'Mynor Black';
    src: url(./fonts/Mynor-BlackWide.otf);
    font-weight: normal;
}

@font-face {
    font-family: 'Mynor';
    src: url(./fonts/Mynor-BookWide.otf);
    font-weight: normal;
}

@font-face {
    font-family: 'Mynor';
    src: url(./fonts/Mynor-BoldWide.otf);
    font-weight: 700;
}