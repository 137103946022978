.editor-container {
    background: #fff;
    border-radius: 5px;
}

.editor-input {
    color: #000;
    padding: 5px 15px;
    outline: 0;
    cursor: auto;
}

.editor-inner {
    position: relative;
}

.editor-placeholder {
    position: absolute;
    top: 21px;
    left: 15px;
    color: #000;
    pointer-events: none;
    opacity: 0.5;
}

.editor-textBold {
    font-weight: 800;
}

.editor-textItalic {
    font-style: italic;
}

.editor-textUnderline {
    text-decoration: underline;
}

.editor-image {
    cursor: default;
    display: inline-block;
    position: relative;
    -webkit-user-select: none;
    user-select: none;
}

.editor-image img {
    max-width: 100%;
}